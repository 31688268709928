<template>
  <div>
    <div class="section-title section-title_app">
      <h3>RESULTADOS DEL DIA</h3>
    </div>
    <div class="columns is-multiline is-gapless">
      <div class="column is-12 has-background-white">
        <div style="display: flex; flex-wrap: wrap; gap: 16px; padding: 16px;">
          <div class="column is-2">
            <b-field label="Año"
                     label-position="on-border">
              <b-datepicker placeholder="2023" v-model="date_order"
                            @input="() => this.getData()"
                            :max-date="maxDatetime"
                            :min-date="minDatetime"/>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Orden"
                     label-position="on-border">
              <b-input v-model="order_no"
                       @input="() => this.getData()"/>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Estudio"
                     label-position="on-border">
              <b-autocomplete
                  v-model="study"
                  placeholder="Radiografía de Torax"
                  @input="handleInputStudy"
                  :loading="searchingStudy"
                  :data="studies"
                  :clearable="true"
                  :clear-on-select="false"
                  @select="handleSelectStudy"
                  class="lapi-monitor-autocomplete"
              >
                <template slot-scope="props">
                  <span class="lapi-monitor-autocomplete__option">{{props.option.code}} - {{props.option.name}}</span>
                </template>
              </b-autocomplete>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Número de Muestra"
                     label-position="on-border">
              <b-input v-model="sample_no"
                       @input="() => this.getData()"/>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Sucursal"
                     label-position="on-border">
              <b-select v-model="branch_office" @input="() => this.getData()">
                <option value="">Todas</option>
                <option :value="branchOffice.id" :key="branchOffice.id"
                        v-for="branchOffice in branchOffices">{{ branchOffice.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Área"
                     label-position="on-border">
              <b-select v-model="area" @input="() => this.getData()">
                <option value=""></option>
                <option :value="areas.code" :key="areas.code"
                        v-for="areas in areas">{{ areas.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Estado"
                     label-position="on-border">
              <b-select v-model="status" @input="() => this.getData()">
                <option value=""></option>
                <option value="onTime">En tiempo</option>
                <option value="late">Atrasada</option>
                <option value="complete">Completada</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-field label="Ver por página"
                     label-position="on-border">
              <b-select v-model="perPage" @input="() => this.getData()">
                <option value="25">25 por página</option>
                <option value="50">50 por página</option>
                <option value="100">100 por página</option>
                <option value="500">500 por página</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-2">
            <b-tooltip label="Exportar Excel">
              <b-button size="is-small" type="is-primary" @click="handleExport">Exportar Excel</b-button>
              <!--<a class="btn is-primary is-small" href="http://api-gateway.lapi.site/v1/monitor/laboratory/export">Exportar Excel</a>-->
            </b-tooltip>
          </div>
          <div class="column is-2">
            <span>Total de encontrados: {{total}}</span>
          </div>
        </div>
      </div>

      <div class="column is-12 is-flex has-background-white lapi-dashboard">
        <div class="lapi-dashboard__item">
          <p class="lapi-dashboard__item-info">{{data.completed}}</p>
          <p class="lapi-dashboard__item-title">Ordenes Completas</p>
        </div>
        <div class="lapi-dashboard__item">
          <p class="lapi-dashboard__item-info">{{data.pendientes}}</p>
          <p class="lapi-dashboard__item-title">Ordenes Pendientes</p>
        </div>
        <div class="lapi-dashboard__item">
          <p class="lapi-dashboard__item-info">{{data.muestras_pendientes}}</p>
          <p class="lapi-dashboard__item-title">Ordenes Con Muestras Pendientes</p>
        </div>
        <div class="lapi-dashboard__item">
          <p class="lapi-dashboard__item-info">{{data.parciales}}</p>
          <p class="lapi-dashboard__item-title">Ordenes Parciales</p>
        </div>
      </div>

      <div class="column is-12 has-background-white">
        <section>
          <b-table
              class="is-size-7"
              :data="rows"
              :loading="loading"
              paginated
              :per-page="perPage"
              :current-page="currentPage"
              bordered
              narrowed
              backend-pagination
              @page-change="getData"
              :total="total"
              sticky-header
              :row-class="rowClass"
              height="100%">
            <b-table-column field="order" label="Orden" v-slot="props">
              {{ props.row.order }}
            </b-table-column>
            <b-table-column field="patientName" label="Nombre" v-slot="props">
              {{ props.row.patientName }}
            </b-table-column>
            <b-table-column field="branchOfficeName" label="Sucursal" v-slot="props">
              {{ props.row.branchOfficeName }}
            </b-table-column>
            <b-table-column field="studyDescription" label="Estudios" v-slot="props">
              {{ props.row.studyDescription }}
            </b-table-column>
            <b-table-column field="nameArea" label="Áreas" v-slot="props">
              {{ props.row.nameArea }}
            </b-table-column>
            <b-table-column field="typeStudy" label="Grupos" v-slot="props">
              {{ props.row.typeStudy}}
            </b-table-column>
            <b-table-column field="date" label="Fecha" v-slot="props">
              {{ props.row.date }}
            </b-table-column>
            <b-table-column field="estimatedDate" label="Entrega Estimada" v-slot="props">
              {{ props.row.estimatedDate }}
            </b-table-column>
            <b-table-column field="status" label="Estado" v-slot="props">
              {{ props.row.status | monitorLabStatus }}
            </b-table-column>
            <b-table-column field="numberOfSample" label="MUESTRA" v-slot="props">
              {{ props.row.numberOfSample }}
            </b-table-column>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon
                        icon="box-open"
                        size="is-large">
                    </b-icon>
                  </p>
                  <p>No hay resultados para mostrar.</p>
                </div>
              </section>
            </template>
          </b-table>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskMonitor",
  props: {},
  data() {
    const maxYear = new Date()
    maxYear.setFullYear(maxYear.getFullYear() + 1, 0, 0)
    const minYear = new Date()
    minYear.setFullYear(minYear.getFullYear() - 3, 12, 1)
    return {
      data: {},
      areas: [],
      date: new Date(),
      area: '',
      order_no: '',
      sample_no: '',
      branch_office: this.$auth.user().active_branch_office,
      maxDatetime: maxYear,
      minDatetime: minYear,
      perPage: 25,
      currentPage: 1,
      total: 0,
      date_order: new Date(),
      order: '',
      status: '',
      study: '',
      loading: true,
      searchingStudy: false,
      studies: [],
      selectedStudy: '',
    }
  },
  computed: {
    branchOffices() {
      return this.$store.state.main.accountBranchOffices;
    },
    transformDate() {
      const new_date = `${this.date_order.getFullYear()}-${(this.date_order.getMonth() + 1).toString().padStart(2, '0')}-${this.date_order.getDate().toString().padStart(2, '0')}`
      return new_date;
    },
    rows() {
      const data = this.data;
      return data && data.data && data.data.data ? data.data.data : []
    }
  },
  mounted() {
    this.wrapper = document.getElementById('main-wrapper');
    this.wrapper.classList.add('is-expanded');
    this.getData()
    this.getAreas()
  },
  methods: {
    getData(page = 1) {
      this.loading = true;
      const params = {
        perPage: this.perPage,
        page,
        order_no: this.order_no,
        branch_office: this.branch_office,
        study: this.study,
        area: this.area,
        date: this.transformDate,
        sample_no: this.sample_no,
        status: this.status,
      }
      this.$http.get('/monitor/laboratory', {params}).then(async ({data}) => {
        this.data = data;
        this.total = data.data.total;
        this.currentPage = page;
      }).finally(() => {
        this.loading = false;
      })
    },
    getAreas() {
      this.$http.get('/areas?perPage=*').then(async ({data}) => {
        this.areas = data.data
      })
    },
    rowClass(item) {
      return "lapi-monitor-" + item.status;
    },
    handleSelectStudy(val) {
      if(val){
        this.selectedStudy = val;
        setTimeout(() => {
          this.study = val.name;
        }, 500);
      }else{
        this.selectedStudy = null;
      }

      this.getData();
    },
    handleInputStudy( val) {
      if(typeof val === 'undefined') return ;
      this.searchingStudy = true;
      const params = {q: this.study};
      this.$http.get('/studies?laboratory=1', {params}).then(({data}) => {
        this.studies = data.data;
      }).finally(() => {
        this.searchingStudy = false;
      })
    },
    handleExport() {
      this.$loader.show();
      const params = {
        order_no: this.order_no,
        branch_office: this.branch_office,
        study: this.study,
        area: this.area,
        date: this.transformDate,
        sample_no: this.sample_no,
        status: this.status
      }
      this.$http.get('/monitor/laboratory/export', {params, responseType: 'blob'}).then(response => {
        const date = this.$moment().format('Y-MM-DD');
        const url = window.URL.createObjectURL(new Blob([response.data],
            { type: response.data.type }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `monitor laboratorio ${date} .xlsx`);
        document.body.appendChild(link);
        link.click();
      }).finally(() => {
        this.$loader.hide();
      })

    }
  }
}
</script>

<style scoped>
.lapi-dashboard {
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.lapi-dashboard__item {
  text-align: center;
  width: 100px;
  height: 130px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.lapi-dashboard__item-info {
  font-weight: bold;
  font-size: 24px;
}

.lapi-dashboard__item-title {
  color: darkgrey;
}

</style>
